<template>
	<div class="servicedetail">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_service_3.png">
        </div>
        <div class="container newsList">
            <div class="row">
                <div class="col-md-9 article">
                    <h1>进出口报关</h1>
                    <div class="articleDetail">
                        <p>公司是中华人民共和国海关总署正式批准的专业报关企业，可在中国境内各主要口岸提供优质服务的专业报关行。</p>
                        <img src="@/assets/img_service_text_3.png" alt="">
                        
                        <p>拥有员工30余人，其中专业报关员15名，具有5年以上工作经验者10余名。丰富的商品知识和业务经验，拥有优质的服务队伍。给客户提供了遵守法律、法规和解决疑难业务问题咨询的保障。定期人员培训，人才储备可靠，科学的管控流程，更加安全、有序、方便、严谨。</p>
                        <p>公司承办各类进出口货物的报关、报检、商品预裁定、客户至上、恪守信誉、安全快捷、为顾客创造最满意的服务是我们努力工作的目标和宗旨。</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contactUs">
                        <div class="contactTitle">联系我们</div>
                        <div class="contactName">Fisher YU</div>
                        <div class="person-contact">
                            <div>
                                <div class="icon font_family icon-icon_phone_white"></div><div>servicepinkoi</div>
                            </div>
                            <div>
                                <div class="icon font_family icon-icon-mail"></div><div>srl-market@srleader.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 成功案例 -->
            <div class="cases">成功案例</div>
            <div class="allCases">
                <div class="case-box-hover"><img src="@/assets/img_service_3_1.png" alt=""><p>跨境货物</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_3_2.png" alt=""><p>私人物品</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_3_3.png" alt=""><p>区区流转</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_3_4.png" alt=""><p>进口保税</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_3_5.png" alt=""><p>进出口清关</p></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "serviceclearance",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "serviceclearance.css";
</style>
